.InfoPageWrapper {
  min-height: calc(100vh);
  position: relative;
}

.pictureBanner {
  position: relative;
  background-position: 0px center;
  height: 300px;
  width: 100%;
  margin: 0 auto;
  background-color: transparent;
  background-size: auto 300%;
  box-shadow: inset 200px 0 100px -100px rgba(0, 0, 0, 1),
    inset -200px 0 100px -100px rgba(0, 0, 0, 1);
}

#miniER {
  background-image: url(./banners/minibanner.jpg);
  background-position: 20vh center;
}

#delftER {
  background-image: url(./banners/delftbanner.jpg);
  background-position: -50vh center;
}

#kaapseER {
  background-image: url(./banners/kaapsebanner.jpg);
  background-position: -50vh center;
}

.info-table {
  border: none;
  margin: 10px 0px 20px 0px;
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.info-table th,
.info-table td {
  border: none;
  vertical-align: middle;
}

.hwh-col1 {
  min-width: 110px;
  background-color: var(--headergrey);
  text-align: center;
  padding: 7px;
  border-radius: 3px;
}

.hwh-col2 {
  padding-left: 15px;
}

.fa-table-hwh {
  display: inline-block;
  font-size: 35px;
  margin: 8px;
}

@media (max-width: 500px) {
  .hwh-col1 {
    min-width: auto;
  }
}

@media (min-width: 800px) {
  .pictureBanner {
    background-position: center;
    animation: animatedBackground 1000s linear infinite;
    -moz-animation: animatedBackground 1000s linear infinite;
    -webkit-animation: animatedBackground 1000s linear infinite;
    -ms-animation: animatedBackground 1000s linear infinite;
    -o-animation: animatedBackground 1000s linear infinite;
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1000vh center;
  }
}
@-moz-keyframes animatedBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1000vh center;
  }
}
@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1000vh center;
  }
}
@-ms-keyframes animatedBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1000vh center;
  }
}
@-o-keyframes animatedBackground {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1000vh center;
  }
}

.bannerTitle {
  margin: auto;
  font-size: 65px;
  text-align: center;
  padding-top: 25px;
  font-family: "Tomorrow", sans-serif;
  color: var(--spannondrood);
  width: auto;
}

.bannerSubTitle {
  margin: auto;
  font-size: 35px;
  text-align: center;
  margin: 20px auto;
  font-style: italic;
  font-family: "Tomorrow", sans-serif;
  color: white;
  width: auto;
}

.infoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 30px;
  max-width: 900px;
  margin: 0px auto 40px auto;
}

.infoText {
  margin-right: 40px;
}

.infoButton {
  text-align: center;
  line-height: 60px;
  display: block;
  width: 150px;
  text-align: left;
  height: 60px;
  background-color: var(--spannondrood);
  color: white;
  font-size: 25px;
  border-radius: 2px;
  font-weight: bold;
  margin-bottom: 30px;
}

.infoButton:hover {
  cursor: pointer;
  background-color: white;
  color: var(--spannondrood);
  border-radius: 8px;
  transition: 0.3s;
}

#buttons-sidebar {
  text-align: center;
}

.buttonIcon {
  display: inline-block;
  text-align: center;
  width: 60px;
}

@media all and (max-width: 1100px) {
  .bannerTitle {
    margin: auto;
    font-size: 40px;
    text-align: center;
    margin-top: 20px;
    font-family: "Tomorrow", sans-serif;
    color: var(--spannondrood);
    width: auto;
  }

  .bannerSubTitle {
    margin: auto;
    font-size: 30px;
    text-align: center;
    margin: 20px auto;
    font-style: italic;
    color: white;
    width: auto;
  }
}

@media all and (max-width: 750px) {
  .bannerTitle {
    margin: auto;
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
    font-family: "Tomorrow", sans-serif;
    color: var(--spannondrood);
    width: auto;
  }

  .bannerSubTitle {
    margin: auto;
    font-size: 20px;
    text-align: center;
    margin: 10px auto;
    font-style: italic;
    color: white;
    width: auto;
  }
}

@media all and (max-width: 750px) {
  .pictureBanner {
    position: relative;
    background-position: 0px center;
    height: 200px;
    box-shadow: inset 100px 0 100px -100px rgba(0, 0, 0, 1),
      inset -100px 0 100px -100px rgba(0, 0, 0, 1);
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.4);
    padding: 13px;
    max-width: 900px;
    margin: 0px auto 40px auto;
  }

  .infoText {
    margin: 5px;
    order: 100;
  }

  .infoButton {
    text-align: center;
    line-height: 50px;
    display: inline-block;
    margin: 10px;
    width: 120px;
    text-align: left;
    height: 50px;
    background-color: var(--spannondrood);
    color: white;
    font-size: 20px;
    border-radius: 2px;
    font-weight: bold;
    text-align: center;
  }
  .InfoPageWrapper .buttonIcon {
    display: inline-block;
    text-align: center;
    width: auto;
    margin-right: 10px;
  }
}
