.Header {
  display: flex;
  overflow: hidden;
  height: auto;
  justify-content: center;
  flex-direction: row;
  border-bottom: 2px solid rgb(50, 50, 50);
  position: fixed;
  z-index: 5;
  width: 100%;
  background-color: var(--headergrey);
}

.Header a {
  color: rgb(170, 170, 170);
  text-decoration: none;
}

.Header .navButton {
  float: center;
  align-items: center;
  padding: 14px 22px;
  font-size: 18px;
  height: 18px;
}

.Header .navButton:hover {
  color: var(--whitish);
  font-weight: 600;
}

.Header .navButton.active {
  color: var(--whitish);
  font-weight: 600;
}

.Header .right {
  float: right;
  cursor: pointer;
  width: 100px;
}

.Header .navImg {
  float: left;
  display: flex;
  height: 30px;
}

.Header .navImg img {
  height: 30px;
  padding-right: 20px;
}

.Header .icon {
  display: none;
}

#language-toggle {
  position: absolute;
  right: 0;
  color: #636363;
}

#language-toggle:hover {
  background-color: inherit !important;
  color: var(--whitish) !important;
}

#language-toggle span {
  cursor: pointer;
}

#language-toggle .active {
  color: var(--whitish) !important;
}

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the Header (.icon) */
@media screen and (max-width: 750px) {
  .Header {
    padding-left: 0px;
    flex-direction: column;
  }

  .Header a:not(:first-child) {
    display: none;
  }

  #language-toggle {
    position: static;
  }

  .Header > div {
    display: none;
  }

  .Header .icon {
    position: absolute;
    right: 0;
    top: 0;
    color: #f2f2f2;
    text-align: center;
    padding: 11px 10px;
    height: 26px;
    float: right;
    display: block;
    cursor: pointer;
    width: 25px;
  }

  .Header .icon img {
    height: 100%;
  }
}

/* The "responsive" class is added to the Header with JavaScript when the user clicks on the icon. This class makes the Header look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 750px) {
  .Header.responsive {
    /* position: relative; */
  }
  .Header.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .Header.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .Header.responsive > div {
    display: block;
  }

  .Header.responsive .right {
    float: none;
    display: block;
    text-align: left;
  }

  .Header.responsive .navButton {
    width: 100%;
    margin: 0;
  }
}
