.FAQ {
  min-height: calc(100vh);
  position: relative;
  background-color: rgb(24, 24, 24);
}

.FAQ > section:first-child {
  margin-top: 48px;
}

.rotated {
  transform: rotate(18deg);
}

.faqwrapper {
  margin: 0 auto 30px auto;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 10px;
}

.question {
  padding: 10px;
  font-weight: bold;
  background-color: var(--headergrey);
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.answer {
  padding: 10px;
  font-size: 16px;
  line-height: 1.3;
  display: none;
}

.FAQicon {
  display: inline-block;
  margin-right: 10px;
}

.background-fixed-top {
  background-position: center top !important;
}

#question-not-div {
  font-size: 20px;
  text-align: center;
  text-align: center;
  line-height: 1.5;
  border: 2px solid white;
  border-radius: 10px;
  padding: 20px;
}
