.mySlides {
  position: relative;
  display: none;
  text-align: center;
  margin: 20px 10.55% 0px 10.55%;
  background-color: rgb(39, 39, 39, 0.5);
  height: 197px;
}

.quoteName {
  font-size: 18px;
  margin: 20px;
  padding: 0px 50px 0px 50px;
}

.quoteText {
  font-size: 18px;
  padding: 0px 50px 0px 50px;
  line-height: 1.5;
  letter-spacing: 1.1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.quoteIconWrapper {
  font-size: 24px;
  height: 50px;
  line-height: 50px;
}

@media screen and (max-width: 900px) {
  .mySlides {
    height: 230px;
  }
}

@media screen and (max-width: 750px) {
  .mySlides {
    height: 250px;
  }
  .quoteText {
    padding: 0px 20px 0px 20px;
  }
}

@media screen and (max-width: 550px) {
  .mySlides {
    height: 300px;
  }
  .quoteText {
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  .mySlides {
    height: 300px;
  }
}

@media screen and (max-width: 350px) {
  .mySlides {
    height: 320px;
  }
}
