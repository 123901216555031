.PaymentComplete {
  background-color: rgb(31, 31, 31);
  min-height: calc(100vh);
  position: relative;
}

@media screen and (min-width: 750px) {
  .PaymentComplete {
  background-image: url("../shared/img/darkbackground_small.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
}


#playButton {
  width: auto;
  height: auto;
  padding: 15px 20px 15px 20px;
  line-height: 1;
  margin: 25px auto 5px auto;
  font-size: 21px;
  text-align: center;
}
