.Klassement {
  min-height: calc(100vh);
  position: relative;
}

.Klassement table {
  font-family: "Tomorrow", sans-serif;
  color: rgb(167, 167, 167);
}

.klassement-table td:nth-child(1) {
  width: 60px;
}
.klassement-table td:nth-child(2) {
  width: 300px;
}
.klassement-table td:nth-child(3) {
  width: 130px;
  text-align: center;
}

.klassement-table td {
  padding: 5px 10px;
}

#klassement-headers {
  margin-bottom: 0px;
}

#klassement-headers td {
  background-color: black;
  border: 2px solid black;
  border-bottom: 4px solid var(--spannondrood);
  color: var(--spannondrood);
  font-size: 22px;
}

#klassement-rows {
  margin-top: 0px;
}

#klassement-rows td {
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid black;
}

.custom-scrollbar {
  margin: auto;
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 25px;
}

@media screen and (max-height: 800px) {
  .custom-scrollbar {
    max-height: 300px;
  }
}

@media screen and (max-width: 750px) {
  #klassement-rows {
    font-size: 14px;
  }
  .klassement-table td:nth-child(1) {
    width: 25px;
  }
  .klassement-table td:nth-child(2) {
    width: 200px;
  }
  .klassement-table td:nth-child(3) {
    width: 70px;
  }
  .klassement-table td {
    padding: 5px 4px;
  }
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: #444444;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 20px;
  background-color: #1f1f1f;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a52020;
  background-image: -webkit-linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.3) 50%,
    transparent,
    transparent
  );
}
