.iconBanner {
  position: absolute;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 120px;
  background-color: rgb(58 58 58 / 52%);
}

.iconsWrapper {
  width: 900px;
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}

.iconInfoWrapper {
  font-family: "Tomorrow", sans-serif;
  text-align: center;
  font-size: 25px;
}

.bigIcon {
  margin-bottom: 20px;
}

@media all and (max-width: 750px) {
  .iconsWrapper {
    height: 70px;
    width: 100%;
  }

  .iconBanner {
    position: absolute;
    width: 100%;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 70px;
  }

  .iconInfoWrapper {
    font-family: "Tomorrow", sans-serif;
    text-align: center;
    padding: 5px;
    font-size: 12px;
  }

  .bigIcon {
    margin-bottom: 5px;
  }
}
