.Competition {
  background-color: rgb(24, 24, 24);
  position: relative;
  min-height: calc(100vh);
  position: relative;
}

.Competition > section:first-child {
  margin-top: 48px;
}

.Competition p {
  line-height: 1.3;
}

.cover-bg {
  background-size: cover;
  background-position: center;
}

.smoke-bg {
  background-image: url("./img/smokebg.jpg");
}

.sand-bg {
  background-image: url("./img/sand-bg.jpg");
}

.strikethrough {
  text-decoration: line-through;
}

.including-text-big {
  font-size: 22pt;
  margin-top: 15px;
}

.img-right {
  width: 400px;
  float: right;
  margin-left: 20px;
}

@media (min-width: 900px) {
  #competition-escape-room-text {
    padding-left: 15px;
  }
}

#example-div-cover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 24px;
  z-index: 2;
}

.flex-align-center {
  align-items: center;
}

#example-div-title {
  text-align: center;
}

#example-div {
  height: 250px;
}

.summary-fa {
  color: var(--spannondrood);
  font-size: 3em;
  margin-bottom: 20px;
}

#summary-competition p {
  line-height: 1.4;
}

#summary-competition > div {
  border: 1px solid rgb(80, 80, 80);
  padding: 20px 10px;
}

.klassementen-div {
  position: relative;
  background-color: rgba(99, 99, 99, 0.7);
  padding: 20px 10px 10px 10px;
  text-align: center;
  border: 1px solid rgb(80, 80, 80);
  padding: 20px 10px;
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  #example-div {
    flex: auto;
  }
}
