.PayPageWrapper {
  min-height: calc(100vh);
  position: relative;
  min-width: 330px;
}

.PayPageWrapper p {
  margin: 10px auto;
}

.afrekenButton {
  line-height: 60px;
  display: block;
  width: 210px;
  text-align: left;
  height: 60px;
  background-color: var(--spannondrood);
  color: white;
  font-size: 24px;
  border-radius: 2px;
  font-weight: bold;
  margin-bottom: 30px;
}

.afrekenButton:hover {
  cursor: pointer;
  background-color: white;
  color: var(--spannondrood);
  border-radius: 8px;
  transition: 0.3s;
}

.PayPageBlock {
  width: 454px;
  padding: 30px;
  background-color: rgb(0, 0, 0, 0.4);
  border: 2px solid var(--spannondrood);
  margin: 20px auto 20px auto;
}

.gegevensitem {
  display: inline-block;
  color: var(--spannondrood);
  width: 150px;
}

.gegevensitem_long {
  display: inline-block;
  color: var(--spannondrood);
  height: 23px;
  vertical-align: text-bottom;
  padding-top: 11px;
}

.bestellingitem {
  display: inline-block;
  color: var(--spannondrood);
  width: 122px;
}

.PayPageBlock input[type="text"],
.PayPageBlock input[type="email"] {
  height: 25px;
  margin-top: 4px;
  width: 290px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(45 45 45);
}

#jouwBestelling {
  margin-bottom: 0px;
}

.gegevensitem_long a {
  color: inherit;
  text-decoration: underline !important;
}

/*  Css for checboxes */

.container {
  display: block;
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.PayPageWrapper input {
  outline: none;
}

.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  bottom: 0px;
  right: 3px;
  height: 30px;
  width: 30px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--spannondrood);
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 10px;
  top: 5px;

  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: 0.2s;
}

.errorWrapper {
  height: 20px;
  font-size: 15px;
  color: rgb(94, 255, 0);
  text-align: center;
}

@media (max-width: 550px) {
  .PayPageBlock input[type="text"],
  .PayPageBlock input[type="email"] {
    height: 25px;
    float: none;
    margin-top: 4px;
    width: 250px;
    font-size: 16px;
    font-weight: 600;
    color: rgb(45 45 45);
  }

  .InfoPageTitle {
    font-size: ;
  }

  .InfoPageTitle h3 {
    font-size: 21px;
  }

  .PayPageBlock {
    margin: 10px;
    width: 86%;
    padding: 20px;
  }

  .bestellingitem {
    width: auto;
  }
}
