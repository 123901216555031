.DiffIcon {
  display: inline-block;
  color: var(--spannondrood);
  margin-left: 5px;
}

.lockIcon {
  display: inline-block;
  margin-right: 4px;
}
