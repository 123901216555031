.info-screenshots .thumbnail {
  width: 150px;
  margin: 0px 20px 20px 0px;
}

.full {
  display: none;
  z-index: 2;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.full-image {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80vw;
  max-height: 80vh;
  max-width: 80vw;
}

@media screen and (max-width: 900px) {
  .full-image {
    width: 95vw;
    max-height: 95vh;
    max-width: 95vw;
  }
}

.closeX {
  position: fixed;
  top: 1%;
  right: 1%;
  z-index: 5;
  text-align: right;
  font-size: 40px;
  opacity: 0.8;
}

.closeX:hover {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .closeX {
    opacity: 1;
  }
  .info-screenshots .thumbnail {
    width: 45%;
    margin: 0px 5px 10px 5px;
  }
}

.info-screenshots .thumbnail:hover {
  opacity: 0.7;
  cursor: pointer;
}
