.EscapeRooms {
  min-height: calc(100vh);
  position: relative;
  background-color: rgb(24, 24, 24);
}

#escaperooms-content-wrapper > div:last-child {
  border-bottom-width: 0px;
}

@media screen and (min-width: 750px) {
  .EscapeRooms {
    /* background-image: url("../shared/img/darkbackground_small.jpg"); */
    background-position: center;
    background-size: cover;
  }
}
