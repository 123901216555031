.PageTitle {
  border: 1px solid rgb(218, 0, 29);
  margin: 40px 10px 20px 10px;
}

.middleBorder {
  border: 5px solid rgb(218, 0, 29, 0.5);
}

.innerBorder {
  border: 1px solid rgb(218, 0, 29);
  padding: 5px 19px;
  font-family: "Tomorrow", sans-serif;
  text-transform: uppercase;
  font-size: 44px;
  text-align: center;
  color: rgb(218, 0, 29, 0.15);
  letter-spacing: 3.4px;
}

.outlined {
  -webkit-text-stroke: 1px var(--spannondrood);
}

@media (max-width: 500px) {
  .innerBorder {
    border: 1px solid rgb(218, 0, 29);
    padding: 5px 19px;
    font-family: "Tomorrow", sans-serif;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    color: rgb(218, 0, 29, 0.15);
    letter-spacing: 3.4px;
  }

  .PageTitle {
    margin: 20px 10px 20px 10px;
  }
}
