.DiffIconBig {
  display: block;
  color: var(--spannondrood);
  margin-bottom: 20px;
}

.lockIconBig {
  display: inline-block;
  margin-right: 4px;
}

@media all and (max-width: 750px) {
  .DiffIconBig {
    display: block;
    color: var(--spannondrood);
    margin-bottom: 5px;
  }
}
