.Zakelijk {
  background-color: rgb(24, 24, 24);
  position: relative;
  min-height: calc(100vh);
  position: relative;
}

.Zakelijk > section:first-child {
  margin-top: 48px;
}

.zakelijk-section {
  width: 100%;
}

.zakelijk-content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  clear: both;
}

.Zakelijk p {
  line-height: 1.3;
}

.lock-bg {
  background: rgb(27, 27, 27);
  background-image: url("./img/numberlock.jpg");
  background-size: cover;
  background-position: center;
}

.network-bg {
  background: rgb(27, 27, 27);
  background-image: url("./img/network.jpg");
  background-size: cover;
  background-position: center;
}

.matrix-bg {
  background: rgb(27, 27, 27);
  background-image: url("./img/matrix.jpg");
  background-size: cover;
  background-position: center;
}

.light-bg {
  background: rgb(34, 34, 34);
}

.styled-a {
  text-decoration: underline;
  color: var(--spannondrood);
}

.divBorder-zakelijk {
  opacity: 0.7;
  border: 3px solid var(--spannondrood);
  margin: 15px;
}

.divBorder-zakelijk:hover {
  opacity: 1;
  cursor: pointer;
}

.zakelijk-h2 {
  font-size: 40px;
  font-weight: bold;
  color: var(--whitish);
  font-family: "Tomorrow", sans-serif;
  text-align: center;
  margin: 15px auto 40px auto;
}

.button-wrapper-zakelijk {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  margin-top: 30px;
}

.button-wrapper-zakelijk > * {
  flex-basis: 100%;
}

.including-item {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border: 1px solid rgb(53, 53, 53);
}

.including-div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.including-div > * {
  flex: 1 0 0px;
}

.including-div > div:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.including-div div:first-child .including-fa {
  float: left;
}

.including-div div:last-child .including-fa {
  float: right;
}

.including-div div:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.including-fa {
  font-size: 3em;
  margin: 7px 20px;
  color: rgba(255, 255, 255, 0.7);
}

.including-text {
  margin: auto 20px;
  line-height: 1.3;
}

.quote-div {
  position: relative;
  background-color: rgba(99, 99, 99, 0.4);
  padding: 20px 10px 10px 10px;
  text-align: center;
  border: 1px solid rgb(80, 80, 80);
}

.quote-div > img {
  max-width: 70%;
  max-height: 100px;
  margin: 20px;
}

.quote-icon-zakelijk {
  position: absolute;
  left: 5px;
  top: -15px;
  font-size: 2em;
  color: var(--spannondrood);
}

.process-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}

.process-step {
  background-size: cover;
  background-position: center;
  background-color: black;
  height: 200px;
  width: 48.5%;
  border: 1px solid rgb(53, 53, 53);
  position: relative;
  overflow: hidden;
}

#process-step-1 {
  background-image: url("./img/process_1.jpg");
}

#process-step-2 {
  background-image: url("./img/Maria_3.jpg");
}

#process-step-3-cover,
#process-step-4-cover {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 200px;
  font-size: 24px;
  z-index: 2;
}

.process-step-name {
  width: 100%;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  vertical-align: middle;
  position: absolute;
  z-index: 3;
}

.process-step-name > p {
  display: inline-block;
  vertical-align: middle;
  margin: 8px auto auto auto;
}

.process-step-number {
  display: inline-block;
  vertical-align: middle;
  color: var(--spannondrood);
  border-radius: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid var(--spannondrood);
  text-align: center;
  line-height: 20px;
  margin: 8px 20px auto 20px;
  font-weight: bold;
}

#iframe-scale-down {
  width: 400%;
  -ms-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  -webkit-transform: scale(0.25);
  transform: scale(0.25);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.homepageBtn-zakelijk {
  text-align: center;
  background-color: rgb(218, 0, 29);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0px auto;
  font-size: 22px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  /* border: 2px solid var(--spannondrood); */
  font-family: var(--Tomorrow);
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.subtitle-zakelijk {
  text-align: center;
  font-style: italic;
  margin-bottom: 31px;
  font-size: 23px;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.4);
}

.Zakelijk form {
  width: 60%;
  margin: auto;
}

.Zakelijk label {
  display: block;
  margin: 20px auto;
}

.Zakelijk input,
.Zakelijk textarea {
  box-sizing: border-box;
  margin-top: 5px;
  display: block;
  width: 100%;
  background-color: rgb(47, 47, 47);
  border: none;
  color: white;
  padding: 3px 10px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.Zakelijk textarea {
  min-height: 200px;
}

.width-60 {
  box-sizing: border-box;
  width: 60%;
  margin: auto;
  text-align: center;
}

.white-border {
  border: 2px solid var(--whitish);
  border-radius: 10px;
  padding: 20px;
  margin: 30px auto;
}

@media (max-width: 900px) {
  .Zakelijk form {
    width: 100%;
    margin: auto;
  }
  .width-60 {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .zakelijk-h2 {
    font-size: 25px;
    margin: 5px auto 18px auto;
  }

  .including-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .including-div > div:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .including-div div:first-child .including-fa {
    float: none;
    text-align: center;
  }

  .including-div div:last-child .including-fa {
    float: none;
    text-align: center;
  }

  .including-div div {
    border-radius: 20px;
  }

  .including-text {
    margin: auto 10px;
  }

  .button-wrapper-zakelijk {
    flex-direction: column;
  }

  .flex {
    flex-direction: column;
    gap: 22px;
  }

  .process-wrapper {
    flex-direction: column;
  }

  .process-step {
    width: 100%;
  }

  .process-step-number {
    margin: 5px 5px auto 5px;
  }
}
