.PageNotFound {
  background-color: rgb(31, 31, 31);
  position: relative;
  min-height: calc(100vh);
  position: relative;
}

@media screen and (min-width: 750px) {
  .PageNotFound {
  background-image: url("../shared/img/darkbackground_small.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
}
