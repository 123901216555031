.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0px 10px 0px;
  margin: 0;
  bottom: 0;
  margin-bottom: 0px;
  border-top: 2px solid rgb(50, 50, 50);
  color: rgb(120, 120, 120);
  background-color: var(--headergrey);
}

.Footer a {
  color: rgb(120, 120, 120);
  text-decoration: none;
  display: inline-block;
}

.Footer a:hover {
  color: rgb(185, 185, 185);
  text-decoration: none;
}

.footer-link {
  margin: 5px 16px;
  height: auto;
  font-size: 11pt;
}

.footer-column {
  text-align: left;
  margin-left: 25px;
  margin-bottom: 20px;
}

.footer-column-title {
  margin-bottom: 12px;
}

.Footer li {
  margin-left: -15px;
  margin-bottom: 5px;
  text-align: left;
}

.Footer .fa-before-replacer {
  display: inline-block;
  color: var(--spannondrood);
  opacity: 0.5;
  width: 15px;
  margin-right: 15px;
  font-size: 15px;
  text-align: left;
}

@media screen and (min-width: 1000px) {
  .Footer {
    flex-direction: row;
  }
  .footer-column {
    min-width: 150px;
    margin-left: 30px;
    margin-bottom: 20px;
  }
}
