body {
  background-color: var(--bodygrey);
  /* background-image: url(./img/dard_background.jpg); */
  background-position: center;
  color: var(--whitish);
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  min-width: 320px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --spannondrood: rgb(218, 0, 29);
  --Tomorrow: "Tomorrow", sans-serif;
  --whitish: rgb(214, 214, 214);
  --darkred: #230606;
  --headergrey: rgb(41, 41, 41);
  --bodygrey: rgb(30, 30, 30);
}

.PageSubTitleText {
  font-size: 20px;
  text-align: center;
  text-align: center;
  margin: 20px 10px 30px 10px;
  max-width: 1100px;
  line-height: 1.5;
}

.InfoPageTitle {
  font-family: "Tomorrow", sans-serif;
  margin: 0px auto 10px auto;
  color: var(--spannondrood);
  font-size: 25px;
}

.PageText {
  font-size: 17px;
  line-height: 1.1;
}

.info-banner {
  margin: 10px 30px;
  max-width: 900px;
  text-align: left;
  background-color: black;
  padding: 20px;
  border: 1px solid var(--spannondrood);
  color: var(--whitish);
}

.simple-text {
  line-height: 1.2;
}

.PageFull {
  position: relative;
  min-height: calc(100vh);
}

.PageContentWrapper {
  margin-top: 48px;
  /* margin-bottom: 65px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: stretch; */
  align-items: stretch;
  max-width: 900px;
}

.titleClean {
  margin: 30px 10px;
  max-width: 1100px;
  font-size: 30px;
  color: var(--whitish);
}

.subtitleClean {
  margin: 35px 0px 15px 0px;
  max-width: 1100px;
  font-size: 25px;
  color: var(--whitish);
  text-align: left;
  align-self: stretch;
}

.textClean {
  margin: 10px 10px 30px 10px;
  max-width: 1100px;
  color: var(--whitish);
  text-align: left;
  line-height: 1.3;
}

#loading {
  margin: 20px auto;
}

ol {
  list-style: decimal;
}

li {
  margin: 10px 0px 5px 30px;
}

table {
  margin: 10px;
}

table,
th,
td {
  border: 1px solid var(--whitish);
  padding: 4px;
}

b {
  font-weight: bold;
}

u {
  text-decoration: underline;
}

i {
  font-style: italic;
}

.small {
  font-size: 13px;
}

h2 {
  font-size: 22px;
  display: block;
  margin: 0px auto 30px auto;
  font-weight: bold;
  color: var(--spannondrood);
  font-family: "Tomorrow", sans-serif;
}

h4 {
  display: inline;
  font-weight: bold;
  letter-spacing: 1px;
}

.themakleur {
  color: var(--spannondrood);
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

.flex {
  display: flex;
  gap: 10px;
  justify-content: space-around;
}

.flex > * {
  flex: 1 1 0;
}

.width100 {
  width: 100%;
}

.background-themakleur {
  background-color: var(--spannondrood);
  color: white;
}

.centeralign {
  text-align: center;
}

@media (max-width: 800px) {
  .no-show-mobile {
    display: none;
  }
}
