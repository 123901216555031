.HomepageBody {
  min-height: calc(100vh);
  position: relative;
  background-image: url("./img/lockpicture-dark-escape-rooms.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo {
  width: 100%;
  max-width: 900px;
  margin: 50px 0px 20px 0px;
}

.welkomText {
  font-size: 30px;
  /* max-width: 700px; */
  margin: 0px 10.55%;
  text-align: center;
  font-family: var(--Tomorrow);
  line-height: 1.3;
}

.titleText {
  font-size: 80px;
  max-width: 700px;
  text-align: center;
  font-family: var(--Tomorrow);
  color: var(--spannondrood);
  margin-bottom: 60px;
  line-height: 1.3;
}

.homepageBtn {
  text-align: center;
  background-color: rgb(218, 0, 29);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0px auto;
  font-size: 23px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  /* border: 2px solid var(--spannondrood); */
  font-family: var(--Tomorrow);
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.frontpage-margin {
  margin: auto 10.55% auto 10.55% !important;
}

.divBorder {
  opacity: 0.7;
  border: 3px solid var(--spannondrood);
  margin: 20px 10.55% 0px 10.55%;
}

#bottom-homepage-button {
  margin: 20px 10.55% 20px 10.55%;
}

.divBorder1 {
  border: 5px solid rgb(218, 0, 29, 0);
}

.divBorder:hover {
  opacity: 1;
}

@media screen and (max-width: 750px) {
  .welkomText {
    font-size: 25px;
  }
  .homepageBtn {
    font-size: 23px;
  }
}

@media screen and (max-width: 550px) {
  .welkomText {
    font-size: 22px;
  }
  .homepageBtn {
    font-size: 22px;
  }
}

@media screen and (max-width: 425px) {
  .welkomText {
    font-size: 20px;
  }
  .homepageBtn {
    font-size: 20px;
  }
}
