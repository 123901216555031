.EscapeRoomTile {
  border-bottom: 2px solid rgb(110, 110, 110);
  padding-bottom: 30px;
  max-width: 1025px;
  margin: 30px auto auto auto;
}

.ERtitle {
  font-size: 22px;
  display: block;
  margin: 17px auto 12px auto;
  font-weight: bold;
  color: var(--spannondrood);
  font-family: "Tomorrow", sans-serif;
}

.innerERtile {
  opacity: 0.7;
  background-color: rgb(0, 0, 0, 0.8);
  border: 1px solid #2a2a2a;
}

.innerERtile:hover {
  opacity: 1;
  cursor: pointer;
  transition: 0.2s;
}

.ERpicture {
  display: inline-block;
  width: 220px;
  height: 220px;
  background-color: grey;
  vertical-align: top;
  position: relative;
  background-position: center;
  background-size: 150%;
  transition: 0.5s;
}

.zoomhover {
  background-size: 160%;
}

#prevpicdelftER {
  background-image: url("../img/escape_room_delft_tile.jpg");
}

#prevpicminiER {
  background-image: url("../img/escape_room_gratis_tile.jpg");
}

#prevpickaapseER {
  background-image: url("../img/escaperoom_recept_tile.jpg");
}

#prevpicschipER {
  background-image: url("../img/escape_room_treasure_tile.jpg");
}

.ERdescr {
  display: inline-block;
  vertical-align: top;
  margin-left: 22px;
  width: 630px;
}

.ERtext {
  display: block;
  height: 134px;
  line-height: 1.15;
}

.ERinfo {
  display: block;
}

.infoItem {
  display: inline-block;
  margin-right: 20px;
  font-weight: bold;
}

#difficulty {
  display: inline-block;
  width: 234px;
}

#estTime {
  width: 198px;
}

.tileButtons {
  padding-top: 10px;
  display: inline-block;
  text-align: center;
}

.tileButton {
  line-height: 40px;
  text-align: left;
  display: block;
  width: 120px;
  margin: 20px 15px 20px 15px;
  height: 40px;
  background-color: var(--spannondrood);
  color: white;
  font-size: 18px;
  border-radius: 2px;
  font-weight: bold;
}

.button_inactive {
  opacity: 0.3;
}

.tileButton:hover {
  cursor: pointer;
  background-color: white;
  color: var(--spannondrood);
  font-size: 18px;
  border-radius: 8px;
  transition: 0.3s;
}

.buttonIconRooms {
  display: inline-block;
  text-align: center;
  width: 45px;
}

.freeBanner {
  background-color: rgba(114, 114, 114, 0.623);
  color: white;
  height: 40px;
  font-size: 30px;
  text-align: center;
  width: 230px;
  line-height: 40px;
  position: absolute;
  bottom: 20px;
  right: -5px;
}

.notliveBanner {
  background-color: rgba(114, 114, 114, 0.623);
  color: white;
  height: 40px;
  font-size: 26px;
  text-align: center;
  width: 230px;
  line-height: 40px;
  position: absolute;
  bottom: 20px;
  right: -5px;
}

.priceBanner {
  background-color: rgba(114, 114, 114, 0.623);
  height: 40px;
  font-size: 26px;
  text-align: center;
  width: 230px;
  line-height: 40px;
  position: absolute;
  bottom: 20px;
  right: -5px;
}

.red {
  color: var(--spannondrood);
}

.ERinfoSmall {
  display: none;
}

@media all and (max-width: 1040px) {
  .ERtitle {
    margin-top: 10px;
  }
  .ERtext {
    height: auto;
    margin-bottom: 10px;
  }
  .tileButtons {
    padding-top: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
  }
  /* .tileButton {
    text-align: center;
  } */
  .ERdescr {
    width: 527px;
    margin-left: 10px;
  }
  .infoItem {
    width: auto !important;
    margin-right: 15px;
  }
  .EscapeRoomTile {
    margin: 30px 5px 30px 5px;
  }
}

@media all and (max-width: 785px) {
  .ERinfoSmall {
    display: block;
  }

  .ERinfo {
    display: none;
  }

  .innerERtile {
    opacity: 1;
  }
  .ERdescr {
    display: block;
    width: auto;
    margin: 0px;
  }
  .ERpicture {
    width: 100%;
    height: 180px;
    background-size: cover;
  }
  .ERpicture:hover {
    background-size: cover;
  }
  .ERtitle {
    margin: 20px 10px 12px 10px;
  }
  .ERtext {
    margin: 10px 10px;
    display: block;
    height: auto;
    line-height: 1.15;
  }
  .ERinfo {
    background-color: rgb(59, 59, 59);
    padding: 10px 30px;
  }
  .infoItem {
    display: block;
    margin-top: 10px;
    width: auto !important;
  }
  .freeBanner {
    width: auto;
    padding: 3px 15px;
    right: -5px;
    top: -12px;
  }
  .notliveBanner {
    width: auto;
    padding: 3px 15px;
    right: -5px;
    top: -12px;
  }
  .priceBanner {
    display: none;
  }
  .tileButtons {
    padding-top: 10px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
  }
  .tileButton {
    line-height: 25px;
    text-align: center;
    display: inline-block;
    width: auto;
    margin: 10px;
    height: auto;
    font-size: 16px;
    padding: 8px 10px 10px 8px;
  }
  .buttonIconRooms {
    width: 28px;
  }
  .EscapeRoomTile {
    border-bottom: 0px solid rgb(255 255 255);
    padding-bottom: 0px;
    margin: 21px 10px;
  }
}
